<template lang="pug">
  div
    v-nav-tabs(
      v-if="!isMobile"
      :options="tabs")
    v-mobile-tabs(
      v-else
      :options="tabs")
</template>

<script>
import adaptationMixin from '@/mixins/adaptation.mixin'
import { mapGetters } from 'vuex'
import permissions from '@/util/permissions'

export default {
  mixins: [adaptationMixin],
  computed: {
    ...mapGetters(['user']),
    tabs () {
      return this.makeTabs()
    }
  },
  methods: {
    makeTabs () {
      return [
        {
          title: 'layouts.sidebar.employees',
          path: '/farm/settings/employees',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_user)
        },
        {
          title: 'layouts.sidebar.mixer',
          path: '/farm/settings/mixer',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_mixer)
        },
        {
          title: 'layouts.sidebar.buildings',
          path: '/farm/settings/buildings',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_housing)
        },
        {
          title: 'layouts.sidebar.silage',
          path: '/farm/settings/silage',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_silage)
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
