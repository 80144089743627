<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.mixer.create.title' | translate }}
    v-card-content
      v-text-field.mb-3(
        :label="$t('base.name')"
        v-model="form.name")
      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('pages.settings.capacity_from')"
            v-model="form.minWeight")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('pages.settings.capacity_to')"
            v-model="form.maxWeight")

      //- v-select.mb-3(
      //-   :label="$t('modals.mixer.create.auto_switch')"
      //-   item-name="label"
      //-   item-value="value"
      //-   :options="options"
      //-   v-model="form.auto_load")

      //- v-switch.mb-3(
      //-   :label="$t('modals.mixer.create.weight_up')"
      //-   v-model="form.weight_up")

      //- v-switch(
      //-   :label="$t('modals.mixer.create.rounding')"
      //-   v-model="form.rounding")

    v-card-actions
      v-btn(
        :disabled="!isNameValid || !isMaxWeightValid || !isMinWeightValid"
        :loading="loading"
        @click="onCreate") {{ 'actions.create' | translate }}

</template>

<script>
export default {
  name: 'CreateNewModal',

  data: () => ({
    form: {
      name: '',
      minWeight: '',
      maxWeight: '',
      weight_up: false,
      rounding: false,
      auto_load: null
    },
    options: [
      { id: 0, label: 'base.turned_off', value: 0 },
      { id: 1, label: '3 сек', value: 3 },
      { id: 2, label: '5 сек', value: 5 },
      { id: 3, label: '7 сек', value: 7 },
      { id: 4, label: '10 сек', value: 10 }
    ],
    loading: false
  }),

  computed: {
    isNameValid () {
      return !!this.form.name
    },

    isMaxWeightValid () {
      return !!this.form.maxWeight
    },

    isMinWeightValid () {
      return !!this.form.minWeight
    }
  },

  methods: {
    async onCreate () {
      this.loading = true
      const payload = {
        name: this.form.name,
        min_weight: +this.form.minWeight,
        max_weight: +this.form.maxWeight,
        weight_up: !!this.form.weight_up,
        rounding: !!this.form.rounding,
        auto_load: +this.form.auto_load
      }
      const { data, error } = await this.$store.dispatch('addMixer', payload)
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(msg, {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
