<template lang="pug">
  div
    .sidebar-layout__toolbar
      button.btn.btn--brand(
      v-permission-hide="permissions.create_mixer"
        @click="modals.showCreateNew = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ 'actions.add_mixer' | translate }}

      v-dialog(
        max-width="350"
        v-model="modals.showCreateNew")
        create-new-modal(
          @close="modals.showCreateNew = false")

    navigation

    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      mixer-table

    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      mixer-table-mobile

</template>

<script>
  import adaptationMixin from '@/mixins/adaptation.mixin'

  import Navigation from '../common/Navigation'

  import CreateNewModal from './modals/CreateNewModal'
  import permissions from '@/util/permissions'

  const MixerTable = () => import('./MixerTable')
  const MixerTableMobile = () => import('./MixerTableMobile')

  export default {
    name: 'SettingsMixer',

    mixins: [adaptationMixin],

    components: {
      Navigation,
      MixerTable,
      MixerTableMobile,
      CreateNewModal
    },

    data: () => ({
      modals: {
        showCreateNew: false
      },
      permissions: permissions
    })
  }
</script>

<style lang="scss" scoped>
</style>
